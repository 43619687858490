@use '../variables';

.SelectWholesaler {
  hr {
    border: none;
    height: 1px;
    background-color: #dddfe1;
  }
  td button {
    color: variables.$color-olive !important;
  }
  .Pagination {
    justify-content: flex-end;
  }
}
