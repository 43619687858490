@use '../variables';
@use '../mixins';

.EditShopModal {
  overflow-y: auto;
  max-height: 80vh;
  button {
    margin-top: 1rem;
  }
  .span {
    margin-bottom: 1rem;
  }

  .wholesalers-table {
    width: 100%;
    border-spacing: 5px;
    th {
      text-align: left;
    }
    td {
      vertical-align: top;
      padding: 0;
      button {
        cursor: pointer;
        padding: 0;
        margin: 0;
        border: none;
        background-color: transparent;
        color: variables.$color-danger;
        display: inline-flex;
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
    td:nth-child(2) {
      width: 100%;
    }
    td:nth-child(3) {
      text-align: center;
    }
  }

}
