@use 'variables';
@use 'mixins';

@font-face {
  font-family: RocGrotesk;
  src: url('/resource/font/roc-grotesk-bold.woff2');
  font-weight: 600;
  font-stretch: normal;
}
@font-face {
  font-family: RocGrotesk;
  src: url('/resource/font/roc-grotesk-regular.woff2');
  font-weight: 400;
  font-stretch: normal;
}
@font-face {
  font-family: RocGrotesk;
  src: url('/resource/font/roc-grotesk-wide-bold.woff2');
  font-weight: 600;
  font-stretch: expanded;
}
@font-face {
  font-family: RocGrotesk;
  src: url('/resource/font/roc-grotesk-condensed-bold.woff2');
  font-weight: 600;
  font-stretch: condensed;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: RocGrotesk, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: variables.$color-background;
  color: variables.$color-text;
}

.App {
  display: flex;
  flex-direction: row;
  height: 100vh;
  @include mixins.up-to-sm {
  flex-direction: column;
  width: 100vw;
  }
}

.DropdownStyle {
  padding: 0 2rem;
}
.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left;
}

.color-secondary {
  color: variables.$color-secondary !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.mt-025 {
  margin-top: .25rem !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-1-75 {
  margin-top: 1.75rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-error {
  margin-bottom: 1.15rem !important;
}

.clickable {
  cursor: pointer;
}

.padding-right-05 {
  padding-right: .5rem !important;
}

.noPadding {
  padding: 0 !important;
}

.row {
  flex-direction: row !important;
}
